<template>
<div class="s">
  <img @click="prePage" class="swiper-btn" src="@/assets/icon_left.png"/>
  <div class="s-c">
      <swiper
        ref="mySwiper"
        :modules="modules"
        :loop="true"
        :slides-per-view="1"
        :autoplay="{ delay: 3000, disableOnInteraction: false }"
        @swiper="onSwiper"
        @slideChange="onSlideChange"
      >
        <SwiperSlide v-for="(item,index) in imgList" :key="index">
          <img class="photo" :src="item" />
        </SwiperSlide>
      </swiper>
  </div>
  <img @click="nextPage" class="swiper-btn" src="@/assets/icon_right.png"/>
</div>
  
</template>

<script setup>
/* eslint-disable */
import { Autoplay, EffectFade } from 'swiper';
import { Swiper, SwiperSlide,useSwiper } from 'swiper/vue';

import 'swiper/css';
import 'swiper/css/bundle';
import {onMounted,ref,toRef} from "vue";
const modules = [Autoplay];
let imgList = ref([
  require('@/assets/img_1.png'),
  require('@/assets/img_2.png'),
  require('@/assets/img_3.png'),
  require('@/assets/img_4.png'),
  require('@/assets/img_5.png'),
  require('@/assets/img_6.png'),
])
let mySwiper = ref(null);

const onSwiper = () => {
  
};
const onSlideChange = () => {
  console.log('slide change');
};

// 获取swiper属性

onMounted(()=>{
 
  console.log('mySwiperRef.value:',mySwiper.value.$el.swiper) 
})
function nextPage() {
  mySwiper.value?.$el.swiper.slideNext();
}

function prePage() {
  mySwiper.value?.$el.swiper.slidePrev();
}
</script>

<style scoped>
.s{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(1344 / 1920 * 100vw);
    height: calc(560 / 1920 * 100vw);
    margin: calc(64 / 1920 * 100vw) 0px;
}
.s-c{
    width: calc(1120 / 1920 * 100vw);
    height: calc(560 / 1920 * 100vw);
}
.swiper-btn{
    width: (64 / 1920 * 100vw);
    height: calc(64 / 1920 * 100vw);
}
.photo{
  width: calc(1120 / 1920 * 100vw);
  height: calc(560 / 1920 * 100vw);
}
</style>
