<template>
  <div class="bg">
    <div class="top">
        <img class="logo" src="@/assets/logo.png" />
        <div @click="mpFlag = 1" class="mp-btn flex-center wx-btn">微信小程序</div>
        <div @click="mpFlag = 2" class="mp-btn flex-center alipay-btn">支付宝小程序</div>
    </div>
    <div v-if="mpFlag>0" class="cube">
        <div class="c-g">
            <img :src="mp_url[mpFlag-1]" style="width:100%" />
        </div>
        <div>扫码体验</div>
    </div>
    <div class="content">
        <img class="title" src="@/assets/title.png" />
        <div class="tag-c">
            <div class="tag">
                <img class="tag-icon" src="@/assets/icon_check.png">
                精准AI识别
            </div>
            <div class="tag">
                <img class="tag-icon" src="@/assets/icon_check.png">
                秒变漫画脸
            </div>
            <div class="tag">
                <img class="tag-icon" src="@/assets/icon_check.png">
                无限次制作
            </div>
            <div class="tag">
                <img class="tag-icon" src="@/assets/icon_check.png">
                多风格保存
            </div>
        </div>
        <MySwiper></MySwiper>
        <div style="display:flex">
            <div @click="openUrl('https://apps.apple.com/us/app/id6450296173')" style="margin-right:40px" class="download flex-center">
                <img class="d-i" src="@/assets/icon_ios.png">
                立即下载
            </div>
            <div @click="openUrl('https://url.cloud.huawei.com/mFz2OJ3ugM')" class="download flex-center">
                <img class="d-i" src="@/assets/icon_android.png">
                立即下载
            </div>
        </div>
        
    </div>
  </div>
  <div class="foot">
    <div class="foot-t flex-center">
        <div style="margin-right:40px"><a style="color:#FFFFFF" href="https://operating.applet.leqi.us/agreement.html?mark=a4b6d236b44a5889a2e00a4ab">服务条款</a></div>
        <div><a style="color:#FFFFFF" href="https://operating.applet.leqi.us/agreement.html?mark=5d004a9e6457ea4ef6d031b20  ">隐私条款</a></div>
    </div>
    <div style="margin-top:40px" class="t-t">联系方式 0510-81819939</div>
    <div class="t-t"> 联系邮箱 cartoonface@leqigroup.com </div>
    <div class="t-t">Copyright@2023 北京乐其爱技术服务有限公司</div>
    <div style="text-decoration:underline" class="t-t"><a style="color:#FFFFFF" href="https://beian.miit.gov.cn/#/Integrated/index"> 苏ICP备14019013号-11 </a></div>
  </div>
</template>

<script setup>
import {ref} from "vue"
import MySwiper from "@/components/mySwiper.vue"
let mpFlag = ref(0); //1:wx,2:alipay

let mp_url = ref([
    require('@/assets/wxmp.jpeg'),
    require('@/assets/alipaymp.jpeg'),
   
])
function openUrl(url){
    location.href = url
}
</script>

<style scoped>
.bg{
    width: 100%;
    height: calc(1173 / 1920 * 100vw);
    background-image: url("../assets/bg.png");
}
.foot{
    width: 100%;
    height: calc(300 / 1920 * 100vw);
    background: #000000;
    font-weight: 400;
    font-size: calc(16 / 1920 * 100vw);
    color: #FFFFFF;
}
.top{
    height: calc(40 / 1920 * 100vw);
    padding: calc(20 / 1920 * 100vw) calc(40 / 1920 * 100vw);
}
.content{
    width: 100vw;
    height: calc(1093 / 1920 * 100vw);
    display: flex;
    align-items: center;
    flex-direction: column;
}
.logo{
    width: calc(144 / 1920 * 100vw);
    height: calc(40 / 1920 * 100vw);
    float: left;
}
.mp-btn{
    width: calc(120 / 1920 * 100vw);
    height: calc(40 / 1920 * 100vw);
    border: 1px solid #000000;
    border-radius: calc(8 / 1920 * 100vw);
    float: right;
    cursor: pointer;
    font-size: calc(16 / 1920 * 100vw);
}

.wx-btn{
    color: #000000;   
}
.alipay-btn{
    background: #000000;
    color: #fff;
    margin-right: calc(32 / 1920 * 100vw)
}
.title{
    width: calc(976 / 1920 * 100vw);
    height: calc(96 / 1920 * 100vw);
    margin-top: calc(48 / 1920 * 100vw);
    margin-bottom: calc(24 / 1920 * 100vw);
}
.tag-c{
    width: calc(976 / 1920 * 100vw);
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.tag{
    font-weight: 400;
    font-size: calc(32 / 1920 * 100vw);
    color: #000000;
    letter-spacing: calc(4 / 1920 * 100vw);
    display: flex;
    align-items: center;
}
.tag-icon{
    width: calc(36 / 1920 * 100vw);
    height: calc(36 / 1920 * 100vw);
    margin-right: calc(8 / 1920 * 100vw);;
}
.download{
    width: calc(254 / 1920 * 100vw);
    height: calc(80 / 1920 * 100vw);
    background: #9D46FF;
    border-radius: 8px;
    font-weight: 500;
    font-size: calc(32 / 1920 * 100vw);
    color: #FFFFFF;
    cursor: pointer;
}
.d-i{
    width: calc(40 / 1920 * 100vw);
    height: calc(40 / 1920 * 100vw);
    margin-right: calc(24 / 1920 * 100vw);
}
.foot-t{
    width: 100vw;
    height: calc(80 / 1920 * 100vw);
    border-bottom: 1px solid #979797;
}
.t-t{
    text-align: center;
    margin-bottom: calc(4 / 1920 * 100vw);
}
.cube{
    position: absolute;
    top: calc(96 / 1920 * 100vw);
    right: calc(60 / 1920 * 100vw);
    padding: calc(10 / 1920 * 100vw);;
    background: #FFFFFF;
    box-shadow: 0 2px 32px 0 #0000001a;
    border-radius: calc(8 / 1920 * 100vw);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: calc(14 / 1920 * 100vw);;
    color: #303133;
    transition: all 0.3s;
}
.c-g{
    width: calc(176 / 1920 * 100vw);
    height: calc(176 / 1920 * 100vw);
    margin-top: calc(8 / 1920 * 100vw);
    margin-bottom: calc(4 / 1920 * 100vw);
    padding: calc(4 / 1920 * 100vw);
}
</style>