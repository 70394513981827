<template>
  <router-view></router-view>
</template>

<script>
</script>

<style>
html,body{
	padding: 0;
	margin: 0;
}
.flex-center{
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
